/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import 'bootstrap';
import './styles/index.scss';
import { Luminous, LuminousGallery } from 'luminous-lightbox';

// import assets
import background from './assets/background.jpg';

// luminous lib
const galleryOpts = {
  arrowNavigation: true,
};
const luminousOpts = {
  closeOnScroll: true,
  showCloseButton: true,
};
new LuminousGallery(document.querySelectorAll('.gallery-image'), galleryOpts, luminousOpts);

// rellax lib
const Rellax = require('rellax');

const rellax = new Rellax('.rellax', {
  breakpoints: [576, 768, 992],
});
